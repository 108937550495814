import SweetAlert from 'sweetalert2';
import type {SweetAlertIcon} from "sweetalert2";

export function useSweetAlert() {

	function customAlert(title: string, text: string, icon: SweetAlertIcon): void {
		SweetAlert.fire({
			title,
			text,
			icon,
			confirmButtonColor: 'var(--alert-button)',
			confirmButtonText: 'Rendben',
		});
	}

	function customSuccessAlert(title: string, text: string): void {
		customAlert(title, text, 'success');
	}

	function customErrorAlert(title: string, text: string): void {
		customAlert(title, text, 'error');
	}

	function customWarningAlert(title: string, text: string): void {
		customAlert(title, text, 'warning');
	}

	function successSaveAlert(): void {
		customSuccessAlert('Sikeres mentés', 'Az adatok módosítása sikeresen megtörtént!');
	}

	function errorSaveAlert(): void {
		customErrorAlert('Hiba', 'A művelet során valami hiba történt!');
	}

	function showAdvertLoadingAlert(): void {
		SweetAlert.fire({
			title: 'Kérlek várj...',
			html: '',
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				SweetAlert.showLoading(null)
			},
		});
	}

	return {
		customAlert,
		customSuccessAlert,
		customErrorAlert,
		customWarningAlert,
		successSaveAlert,
		errorSaveAlert,
		showAdvertLoadingAlert
	}
}

import {useCookie, useState} from "#app";
import type {IUser} from "~/interfaces/auth/IUser";

export default function useAuthStatement() {
	const cookie = useCookie('_token');
	const authState = useState('authStatement', () => ({
		isLoggedIn: cookie.value !== '',
		user: null as IUser | null,
		isLoaded: false,
	}));

	const currentUser = computed((): IUser | null => {
		return authState.value.user;
	});

	async function getMe(): Promise<void> {
		const {$api} = useNuxtApp();
		const {data: response, pending, error} = await $api.auth.getMe();
		if (response.value) {
			authState.value.user = response.value.user;
			authState.value.isLoaded = true;
		}

		if (error.value) {
			clearToken();
		}
	}

	function setToken(token: string): void {
		cookie.value = token;
		authState.value.isLoaded = true;
	}

	function clearToken(): void {
		cookie.value = null;
		authState.value.isLoaded = false;
	}

	return {
		isLoaded: authState.value.isLoaded,
		setToken,
		clearToken,
		getMe,
		currentUser,
	}
}

